var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'date',
    'count_of_items',
    'description',
    'note' ],"formats":{
    date: 'Date',
    count_of_items: 'Integer6',
  },"itemsPerPage":"10","searchOnStart":true,"table":"outflows"}})}
var staticRenderFns = []

export { render, staticRenderFns }